import * as React from "react"
import Header from "../common/components/organism/Header"
import Footer from "../common/components/organism/Footer";
import IframeRender from "../common/components/organism/IframeRender";

const NotFoundPage = () => (
  <>
    <Header />
    <IframeRender fullWidth={true} source={'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1344.2926168675347!2d3.380398325873326!3d6.559758385922395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8d5d28792841%3A0xc5d126816265a9ca!2sElla&#39;s%20dailies!5e0!3m2!1sen!2sng!4v1646950652705!5m2!1sen!2sng width="600" height="450" style="border:0;'} width={'500'} height={'800'} />
    <Footer />
  </>
)

export default NotFoundPage
