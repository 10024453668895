import React from 'react';
import MaxWidth from '../../molecules/Container';

export interface IFrameRenderProps {
  source: string;
  width?: string;
  height?: string;
  fullWidth?: boolean;
}

 const IframeRender = ({fullWidth, source, width, height, ...props}: IFrameRenderProps) => {
  if(fullWidth) {
    return (
      <div className='block my-20'>
        <iframe
        src={source}
        width={width || "100%"}
        height={height || "670"}
        {...props}
        ></iframe>
      </div>
    )
  } else {
    return (
      <MaxWidth>
        <iframe
        src={source}
        width={width || "100%"}
        height={height || "600"}
        {...props}
        ></iframe>
      </MaxWidth>
    )
  }
};

export default IframeRender
